<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-left-panel :data-item='dataItem'></project-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

                <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Proje Düzenleme</h3>

					<p class='text-lg'>
                        <b>Proje Marka Adı</b><br>
                        Proje adının kısaltılmışı yazılmalıdır.<br>
                        <br>
                        <b>Firma Yetkilisi</b><br>
                        Firma yetkili adı, soyadı, gsm ve e-posta bilgileri zorunludur. Firma yetkilisi mutabakat yapılan kişidir.<br>
                        <br>
                        <b>Proje Başlangıç</b><br>
                        Proje başlangıç tarihi ilk servis verilen gün olarak girilmelidir.<br>
                        <br>
                        <b>Proje Bitiş</b><br>
                        Proje bitiş tarihi proje sonlandığında son servisin yapıldığı tarihtir.<br>
                        <br>
                </p>

                </Sidebar>

                </span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>
                <ProjectTabMenu :project-id='projectId'></ProjectTabMenu>
                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label for='name' class='col-12 mb-2 md:col-2 md:mb-0'>Proje Marka Adı<span class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='name' type='text' autocomplete='off' v-model='dataItem.name' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='phone' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Telefon</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='phone' type='text' autocomplete='off' v-model='dataItem.phone' maxlength='10' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='email' class='col-12 mb-2 md:col-2 md:mb-0'>Firma E-Posta</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='email' type='text' autocomplete='off' v-model='dataItem.email' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personName' class='col-12 mb-2 md:col-2 md:mb-0'>Hakediş Yetkilisi<span class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personName' type='text' autocomplete='off' v-model='dataItem.personName' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personPhone' class='col-12 mb-2 md:col-2 md:mb-0'>Hakediş Yetkili GSM<span class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personPhone' type='text' autocomplete='off' maxlength='10' v-model='dataItem.personPhone' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personEmail' class='col-12 mb-2 md:col-2 md:mb-0'>Hakediş Yetkili E-Posta<span class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personEmail' type='text' autocomplete='off' v-model='dataItem.personEmail' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='startDate' class='col-12 mb-2 md:col-2 md:mb-0'>Proje Başlangıç Tarihi<span class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='startDate' :showIcon='true' dateFormat='dd.mm.yy' :showButtonBar='true' v-model='dataItem.startDate'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='endDate' class='col-12 mb-2 md:col-2 md:mb-0'>Proje Bitiş Tarihi</label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='endDate' :showIcon='true' dateFormat='dd.mm.yy' :showButtonBar='true'
                                      v-model='dataItem.endDate'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Durum</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='isActive' v-model='dataItem.isActive' />
                        </div>
                    </div>


                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' @click='save'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>
        
    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import ProjectService from '../../services/projectService';
import AdminToast from '@/components/shared/toast/adminToast';
import moment from 'moment';
import ProjectLeftPanel from './projectLeftPanel';
import ProjectTabMenu from './projectTabMenu';
import { getProject } from '../common/commonFunctions';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';



export default {
    components: { ProjectTabMenu, ProjectLeftPanel, AdminToast },
    _projectService: null,
    created() {
        this._projectService = new ProjectService();
        this.projectId = this.$route.params.projectId;
    },
    async mounted() {
        this.dataItem = await getProject(this.projectId);
        checkActiveProjectIdIsValid(this, this.projectId);
    },
    data() {
        return {
            projectId: 0,
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                startDate: '',
                endDate: '',
                personName: '',
                personPhone: '',
                personEmail: '',
            },

            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async save() {

            if (this.validateForm()) {
                this.dataItem.startDate = moment(this.dataItem.startDate).toDate();
                if (this.dataItem.endDate != null && this.dataItem.endDate != null) {
                    this.dataItem.endDate = moment(this.dataItem.endDate).toDate();
                }

                const updateResponse = await this._projectService.updateProject(this.projectId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Proje Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Proje Güncellemede Sorun İle Karşılaşıldı');
                }
            }

        },

        validateForm() {
            if (this.dataItem.name == null || this.dataItem.name == '') {
                showValidationMessage(this, 'Proje Marka Adı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.personName == null || this.dataItem.personName == '') {
                showValidationMessage(this, 'Hakediş Yetkilisi Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.personPhone == null || this.dataItem.personPhone == '') {
                showValidationMessage(this, 'Hakediş Yetkili GSM Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.personEmail == null || this.dataItem.personEmail == '') {
                showValidationMessage(this, 'Hakediş Yetkili E-Posta Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.startDate == null || this.dataItem.startDate == '') {
                showValidationMessage(this, 'Başlangıç Tarihi Alanı Boş Bırakılamaz');
                return false;
            }


            return true;
        },
    },
};

</script>


<style scoped>

</style>
