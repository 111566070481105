import moment from 'moment';
import store from '@/store';

export function normalizeDate(inputDate) {
    let fixedDate = moment(inputDate).format('yyyy-MM-DD');
    fixedDate = moment(fixedDate).add(3, 'hour').toDate();
    return fixedDate;
}

export function checkActiveProjectIdIsValid(_this, projectId) {
    let activeProjectId = store.getters.getActiveProject.id;
    if (activeProjectId != projectId && getUserType() != "Admin") {
        _this.$router.push({ name: 'access' });
        return;
    }
}

export function checkUserRole(_this, expectedRole) {
    let user = getUser();
    if(user.userType != expectedRole)
    {
        _this.$router.push({ name: 'access' });
    }
}

export function formatDate(value) {
    let newDate = moment(value);
    return newDate.format('DD.MM.YYYY HH:mm');
}

export function getProjects() {
    let projectList = [ { id : -1, name: "Tüm Projeler"} ];
    projectList.push(... store.getters.getProjects);
    return projectList;
}

export function getActiveProjectId() {
    return store.getters.getActiveProject.id;
}

export function getActiveProjectName() {
    return store.getters.getActiveProject.name;
}

export function getActiveProjectDefaults() {
    return store.getters.getActiveProject.projectDefaults
}

export function getUserId() {
    return store.getters.getUserId;
}

export function getUser() {
    return store.getters.getUser;
}

export function getUserType() {
    let user = getUser();
    return user.userType;
}