<template>
    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>

        <div class='profile relative'>
            <img v-if='dataItem.projectLogo != null && dataItem.projectLogo != ""' :src="'http://image.a1catering.com.tr/'+dataItem.projectLogo" width='100'/>
        </div>

        <div class='ml-3 mb-2 md:mb-0'>
            <h3 class='block mb-0'>{{ dataItem.name }}</h3>
            <span class='block subtext mb-3'>{{ dataItem.townName}} - {{ dataItem.cityName }}</span>
        </div>

    </div>
</template>
<script>
export default {
    name: 'ProjectLeftPanel',
    props: {
        dataItem: {},
    },
};
</script>
