<template>
    <TabMenu :model='nestedRouteItems' class='mb-5' />
</template>
<script>

export default {
    name: 'ProjectTabMenu',
    props: {
        projectId: {},
    },
    data() {
        return {
            nestedRouteItems: [],
        };
    },
    created() {
        this.nestedRouteItems =[
            {
                label: 'Temel Bilgiler',
                to: '/Project/Edit/' + this.projectId,
            },
            {
                label: 'Lokasyon',
                to: '/Project/Location/' + this.projectId,
            },
            {
                label: 'Resmi Bilgiler',
                to: '/Project/Official/' + this.projectId,
            },
            {
                label: 'Dosyalar',
                to: '/Project/Files/' + this.projectId,
            },

            {
                label: 'Ayarlar',
                to: '/Project/Settings/' + this.projectId,
            },
            {
                label: 'Öğünler',
                to: '/Project/Defaults/' + this.projectId,
            }
        ]
    },
};
</script>